<div id="popup-deals" *ngIf="isShowing">
    <div class="popup-deals-body">
        <button type="button" class="popup-close-btn" (click)="onClosePopupHandler()">
            <i class="fas fa-times"></i>
        </button>
        <div class="popup-image">
            <picture>
                <source srcset="{{ mobileImgUrl }}" type="image/jpg" media="(max-width: 768px)">
                <source srcset="{{ desktopImgUrl }}" type="image/jpg" media="(min-width: 769px)">
                <img src="{{ desktopImgUrl }}" alt="hero promo ramadhan" />
            </picture>
        </div>
        <div class="popup-deals-caption">
            <span class="badge">Penawaran Spesial</span>
            <h2 [innerHTML]="headLine | jejakimaniTitle"></h2>
            <p [innerHTML]="subHeadline | jejakimaniTitle"></p>
            <div class="popup-deals-highlight">
                <h2>Diskon Hingga {{ discount_price }}Jt</h2>
                <h3>Rp {{ cashback_price | number }} <span>+ Cashback</span></h3>
            </div>
            <div class="popup-deals-footer">
                <button class="btn btn-ji" type="button" (click)="onClickWhatsapp()">Konsultasi Gratis</button>
                <span>
                    <sup>*</sup>
                    S&K Berlaku
                </span>
            </div>
        </div>
    </div>
</div>