import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { contactSales } from 'src/app/helpers/whatsapp.redirector';

declare var gtag: any;

@Component({
  selector: 'app-popup-deals',
  templateUrl: './popup-deals.component.html',
  styleUrls: ['./popup-deals.component.scss']
})

export class PopupDealsComponent implements OnInit {

  isShowing: boolean = false;
  currentMs = moment();
  uptimeDate = moment("2025-04-08 00:00:00");
  downtimeDate = moment("2025-04-20 23:59:59");
  desktopImgUrl: string = "/assets/img/jejakimani/deals/mss-desktop.png";
  mobileImgUrl: string = "/assets/img/jejakimani/deals/mss-mobile.png";
  headLine: string = 'Jangan Lewatkan Promo Terbatas!';
  subHeadline: string = 'Raih ketenangan hati bersama jejak imani';
  discount_price: string = '6.5';
  cashback_price: number = 1000000;
  cta: string = '/whatsapp_Pop Up Deals';

  constructor() { }

  ngOnInit(): void {
    this.getAdsSettings();
  }

  getAdsSettings(): void {
    const isValid = moment(this.currentMs).isBetween(this.uptimeDate, this.downtimeDate);
    const adsSettings = localStorage.getItem("jejakimani-popup-deals");
    if (!adsSettings || adsSettings == null) {
      localStorage.setItem("jejakimani-popup-deals", "");
      if (isValid) this.onIdleStart();
    } else {
      const lastClosed = moment(parseInt(adsSettings, 10) + 1800000);
      const isAfter = moment(this.currentMs).isAfter(lastClosed);
      if (isValid && isAfter) this.onIdleStart();
    }
  }

  onIdleStart(): void {
    setTimeout(() => {
      document.body.style.overflow = 'hidden';
      this.isShowing = true;
    }, 15000);
  }

  onClosePopupHandler(): void {
    document.body.style.overflow = 'auto';
    this.isShowing = false;
    const timestamp = new Date().getTime();
    localStorage.setItem("jejakimani-popup-deals", String(timestamp));
  }

  onClickWhatsapp = async (): Promise<void> => {
    const response = await contactSales(this.cta);

    if (response) {
        const { whatsapp_api, whatsapp_number, google_tag_event, google_tag_event_label, google_tag_event_category } = response;
        var target = whatsapp_api ?? `https://api.whatsapp.com/send/?phone=${whatsapp_number}`;
        gtag('event', google_tag_event, {
            'page_path': window.location.href,
            'event_category': google_tag_event_category,
            'event_label': google_tag_event_label
        });
        if (!window.open(target)) {
          window.location.href = target;
        }
        // window.open(target);
    }
  }

}
