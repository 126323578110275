import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jejakimaniTitle'
})

export class JejakImaniTitlePipe implements PipeTransform {
    transform(value: string, color?: boolean): string {
        if (! value) {
          return '';
        }

        var string = value.replace(/(?:\r\n|\r|\n)/g, '<br>');
        const regex = new RegExp(/([*])(?:(?=(\\?))\2.)*?\1/g, 'gi');

        const regexHastag = new RegExp(/#(.*?)#/g, 'gi');
        const colorClass = color ? "jejakimani-color" : "";
        const fontFamily = "font-ji";
        string = string.replace(regex, (match) => `<span class="fw-bold `+colorClass+`">${match.replace(/\*/g,'')}</span>`);
        string = string.replace(regexHastag, (match) => `<span class="`+colorClass+` `+fontFamily+`">${match.replace(/\#/g,'')}</span>`);
        return string
      }
}
